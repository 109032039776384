@import "@/styles/globals.scss";
@font-face {
  font-family: 'Circular';
  src: url(fonts/lineto-circular-bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url(fonts/lineto-circular-book.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url(fonts/lineto-circular-bookitalic.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Circular';
  src: url(fonts/lineto-circular-medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url(fonts/lineto-circular-mediumitalic.woff2) format('woff2');
  font-weight: 500;
  font-style: italic;
}

